<template>
  <mega-modal :active="modal" @onClose="modal = false">
    <mega-header :title="$t('embed_manual')" class="bg-lighter">
    </mega-header>
     <div class="card-body">
      {{ $t('embed_customization') }}
        <code class="code-container">
          <pre>
&lt;div id=&quot;arround&quot;&gt;&lt;/div&gt;
&lt;script src=&#039;{{ embedUrl }}&#039;&gt;&lt;/script&gt;
&lt;script&gt;
  Arround._init(
    &#039;arround&#039;,
    {{ arr_id }},
    {{ embedParamsInline }}
  )
&lt;/script&gt;
          </pre>
        </code>
    </div>
     <div class="card-body">
      {{ $t('embed_customization_2') }}
      <code class="code-container">
        Arround._startAr()
      </code>
    </div>
  </mega-modal>
</template>

<script>
export default {
  data() {
    return {
      arr_id: false,
      embedParamsInline: '',
      modal: false,
      embedUrl: process.env.NODE_ENV === 'production' 
          ? 'https://webar.arround.business/api/embed.js'
          : 'https://webar.gora.studio/api/embed.js',
        embedId: Math.round(Math.random() * 1000),
    }
  },
  methods: {
    open(arr, embedParamsInline) {
      this.arr_id = arr
      this.embedParamsInline = embedParamsInline
      this.modal = true;
    }
  }
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mega-modal",
    {
      attrs: { active: _vm.modal },
      on: {
        onClose: function($event) {
          _vm.modal = false
        }
      }
    },
    [
      _c("mega-header", {
        staticClass: "bg-lighter",
        attrs: { title: _vm.$t("embed_manual") }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("embed_customization")) + "\n        "
        ),
        _c("code", { staticClass: "code-container" }, [
          _c("pre", [
            _vm._v(
              '<div id="arround"></div>\n<script src=\'' +
                _vm._s(_vm.embedUrl) +
                "'></script>\n<script>\n  Arround._init(\n    'arround',\n    " +
                _vm._s(_vm.arr_id) +
                ",\n    " +
                _vm._s(_vm.embedParamsInline) +
                "\n  )\n</script>\n          "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("embed_customization_2")) + "\n      "
        ),
        _c("code", { staticClass: "code-container" }, [
          _vm._v("\n        Arround._startAr()\n      ")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }